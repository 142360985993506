<template>
  <div
    v-click-outside="collapse"
    :class="baseClass"
  >
    <div
      class="select-box"
      :class="{
        ['select-box--expanded']: !isCollapsed
      }"
    >
      <div
        class="select-box__label"
        @click="handleDropdownButtonClick"
      >
        <label :for="id">
          <slot name="label"> {{ label }} </slot>
        </label>
        <i
          class="select-box__label__icon"
        >
          <SmallArrowIcon class="select-box__label__icon__small" />
          <ArrowIcon
            class="select-box__label__icon__big"
            :direction="isCollapsed ? 'bottom' : 'top'"
          />
        </i>
      </div>
      <ul
        id="select-box-container"
        tabindex="-1"
        role="listbox"
        class="select-box__option-list"
        :class="{ 'select-box__option-list--expanded': !isCollapsed }"
      >
        <li
          v-for="(element, index) in data"
          :key="index"
          role="option"
          class="select-box__option-list__item"
          :class="{ 'select-box__option-list__item--selected': isSelected(element) }"
          @click.stop="emitInput(element)"
        >
          <SplNuxtLink
            v-if="itemAsLink"
            full-width
            is-external
            :to="addLinkBaseToUri(getUriOrSlug(element))"
          >
            <template v-if="itemLabelAsSlot">
              <slot
                name="item"
                :element="element"
                :index="index"
              />
            </template>
            <template v-else>
              {{ getLabelOrTitle(element) }}
            </template>
          </SplNuxtLink>
          <div
            v-else
            name="item"
            :item="element"
          >
            <template v-if="itemLabelAsSlot">
              <slot
                name="item"
                :element="element"
                :index="index"
              />
            </template>
            <template v-else>
              {{ getLabelOrTitle(element) }}
            </template>
          </div>
        </li>
        <li>
          <slot name="extra-items" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import SplNuxtLink from '../SplNuxtLink.vue'
import SmallArrowIcon from '../icons/SmallArrow.icon.vue'
import ArrowIcon from '../icons/Arrow.icon.vue'

import { joinPaths, slashUri } from '@/core/ts/util/string'

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
    SmallArrowIcon,
    ArrowIcon,
  },
  props: {
    id: { type: String, required: true },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    isDisabled: { type: Boolean, default: false },
    displayProp: { type: String, required: true },
    data: { type: Array, default: [] },
    variant: { type: String, default: () => 'base' }, // base, minified, border, minified-light
    itemAsLink: { type: Boolean, default: false },
    itemLinkBase: { type: String, default: '' },
    itemLabelAsSlot: { type: Boolean, default: false },
    collapseOnInput: { type: Boolean, default: true },
  },
  setup(props, {emit}) {
    const isCollapsed = ref(true)
    const innerValue = ref(null)

    function collapse() {
      isCollapsed.value = true
    }

    function toggleIsCollapsed() {
      isCollapsed.value = !isCollapsed.value
    }

    function handleDropdownButtonClick() {
      if (props.isDisabled) return
      toggleIsCollapsed()

      const inputEl = document.getElementById(`select-box-input-${props.id}`)
      if (inputEl) {
        inputEl.focus()
      }
    }

    watch(() => props.data, () => {
      if (props.collapseOnInput) {
        return
      }

      const elements = document.querySelectorAll('#select-box-container')
      elements.forEach((element) => {
        element.scrollTop = 0
      })
    })

    function emitInput(value: any) {
      if (props.collapseOnInput) {
        collapse()
      }
      emit('input', value)
    }


    function getDisplayProp(value: any) {
      return value[props.displayProp]
    }

    const inputLabel = computed(() => {
      if (innerValue.value) {
        return getDisplayProp(innerValue.value)
      }

      return ''
    })

    const baseClass = computed(() => {
      return `${props.variant}-selector`
    })

    function isSelected(value: any) {
      return JSON.stringify(value) === JSON.stringify(value.value)
    }

    const getUriOrSlug = (item: any) => {
      if (item.uri) {
        return item.uri
      }

      if (item.slug) {
        return `/${item.slug}`
      }

      return ''
    }

    const getLabelOrTitle = (item: any) => {
      if (item.label) {
        return item.label
      }

      if (item.title) {
        return item.title
      }

      if (item.name) {
        return item.name
      }

      return ''
    }

    const addLinkBaseToUri = (uri: string) => slashUri(joinPaths(props.itemLinkBase, uri))

    return {
      isCollapsed,
      collapse,
      inputLabel,
      handleDropdownButtonClick,
      emitInput,
      baseClass,
      isSelected,
      getDisplayProp,
      getUriOrSlug,
      getLabelOrTitle,
      addLinkBaseToUri,
    }
  }
})
</script>

<style lang="scss" scoped>
.item-base {
  @apply py-2;

  @apply mx-15p;
  @apply text-base;
  @apply text-spl-dark;
  @apply cursor-pointer;
  @apply border-b border-spl-gray-1;
  @apply transition-all duration-300;

  @apply text-left;

  &:hover {
    @apply pl-5p;
  }

  &:last-child {
    @apply border-none;
  }
}

.option-list-base {
  @apply absolute;
  @apply right-0;
  @apply top-8;

  @apply text-left;

  @screen md {
    @apply right-0;
  }

  min-width: 235px;
  @apply w-full;
  @apply max-h-300p;
  @apply overflow-auto;

  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.14);
  @apply bg-white;
  @apply rounded-md;
  @apply z-10;
  @apply py-10p;
  @apply opacity-0;
  @apply invisible;
  @apply transform -translate-y-20p;
  @apply transition-all duration-100;

  &:focus {
    text-decoration: none;
    outline: none;
  }
}

.selector-expanded {
  @apply opacity-100;
  @apply visible;
  @apply transform translate-y-0;
}

.selector-label-base {
  @apply text-base;
  @apply text-spl-dark;

  @apply w-full;
  @apply flex justify-between items-center;

  label {
    @apply cursor-pointer;
  }
}

.selector-label-base:hover {
  @apply text-spl-gray-4;
}

.base-selector {
  .select-box {
    // @apply min-w-120p;
    min-width: 120px;
    @apply flex justify-between;
    @apply relative;
    @apply cursor-pointer;

    @apply my-2;
    @apply py-6;
    @apply px-6;

    @apply border-1;
    @apply rounded-md;
    @apply border-spl-gray-2;

    @screen lg {
      @apply my-0;
      @apply p-0;
      @apply border-0;
    }

    &__label {
      @apply selector-label-base;

      &__icon {
        &__small {
          @apply hidden;
          @screen lg {
            @apply block;
          }
        }

        &__big {
          @screen lg {
            @apply hidden;
          }
        }
      }
    }

    &__option-list {
      @apply option-list-base;
      @apply left-10p;

      &--expanded {
        @apply selector-expanded;
      }

      &__item {
        @apply item-base;
      }
    }
  }
}

.minified-selector {
  .select-box {
    @apply relative;

    &__label {
      @apply selector-label-base;

      @apply text-spl-dark;
      @apply underline;

      &__icon {
        @apply hidden;
      }
    }


    &__option-list {
      @apply option-list-base;

      &--expanded {
        @apply selector-expanded;
      }

      &__item {
        @apply item-base;
      }
    }
  }
}

.minified-light-selector {
  .select-box {
    @apply relative;
    @apply px-2;

    &__label {
      @apply selector-label-base;

      @apply flex justify-start items-center;

      @apply text-spl-white;
      @apply underline;

      &__icon {
        @apply pl-1;
        &__small {
          @apply hidden;
        }
      }
    }

    &__label:hover {
      @apply text-spl-gray-1;
    }

    &__option-list {
      @apply option-list-base;

      &--expanded {
        @apply selector-expanded;
      }

      &__item {
        // @apply item-base;
        @apply text-left;
      }
    }
  }
}

.border-selector {
  .select-box {
    @apply relative;

    @apply px-6 py-4;
    @apply border-1;
    @apply rounded-sm;

    &__label {
      @apply selector-label-base;
      @apply text-spl-gray-4;

      label {
        @apply w-full;
      }

      &__icon {
        &__small {
          @apply hidden;
        }
      }
    }

    &__option-list {
      @apply option-list-base;
      // @apply top-50p;

      &--expanded {
        @apply selector-expanded;
      }

      &__item {
        @apply item-base;
      }
    }
  }
}
</style>
